import { IWorkingHours } from '../interfaces';

export const BUSINESS_TYPE = [
    { name: 'Food', value: 'food' },
    { name: 'Digital', value: 'digital' },
    { name: 'Liquor', value: 'liquor' }
]

export const ACTIONS = {
    ACTIVE: 'ACTIVE',
    BLOCKED: 'BLOCKED'
};

export const STATUS = [
    { name: 'ACTIVE', value: 'active' },
    { name: 'BLOCKED', value: 'blocked' },
];

export const DEVICE_TYPE = [
    { name: 'ANDROID', value: 'ANDROID' },
    { name: 'IOS', value: 'IOS' },
    { name: 'WEB', value: 'WEB' }
];

export const OTP_OPTIONS = {
    NUMBER: 'phoneNumber',
    EMAIL: 'email'
};

export const CLAIM_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED'
};
export const BUSINESS_STATUS = {
    PENDING: '1',
    APPROVED: '2',
    // REJECTED: 'REJECTED'
};

export const CLAIM_TITLE = {
    CLAIM: 'claim',
    ADD: 'add'
}


export const WORKING_HOURS: IWorkingHours = {
    mon: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' },
    tue: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' },
    wed: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' },
    thu: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' },
    fri: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' },
    sat: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' },
    sun: { startTime: '', endTime: '', dayOff: false, allDay: false, dayStartTime: '', dayEndTime: '' }
};

export const ROLES = {
    // DASHBOARD: { name: 'Dashboard', value: 'dashboard' },
    BUSINESS: { name: 'Business', value: 'business' },    
    CHAT_BOT: { name: 'Chat Bot', value: 'chatBot' },
    WALLET: { name: 'Wallet', value: 'wallet' },
    PAYMENT: { name: 'Payment', value: 'payment' },
    IMAGE_MANAGEMENT: { name: 'Image Management', value: 'imageManagement' },
    SUPPORT: { name: 'Support', value: 'support' },
    USER_MANAGEMENT: { name: 'User Management', value: 'subMerchant' },
    STORY: { name: 'Story', value: 'story' },
    REVIEWS: { name: 'Reviews', value: 'reviews' },
    DEV_CENTER: { name: 'Dev Center', value: 'devCenter' },
}

export const ACCESS_TYPE = {
    NONE: { name: 'None', value: 0 },
    READ: { name: 'Read', value: 1 },
    WRITE: { name: 'Write', value: 2 }
};

export const WALLET_TYPES = {
    NORMAL: 'CUSTOMER',
    BUSINESS: 'BUSINESS',
    LIGHT: 'LIGHT',
    NONE: 'NONE'
};

export const POOL_KYC_TYPE = {
    INDIVIDUAL: 'INDIVIDUAL',
    BUSINESS: 'BUSINESS'
};

export const STORY_TYPE = [
    { value: 'PUBLIC', viewValue: 'Public' },
    { value: 'CONNECTION_ONLY', viewValue: 'Connection Only' },

];

export const QUERY_TYPE = [
    { value: 'QUERY', viewValue: 'Query' },
    { value: 'FEEDBACK', viewValue: 'Feedback' },
    { value: 'REPORT', viewValue: 'Report' },
    { value: 'BUSINESS', viewValue: 'Business' },
    { value: 'CHATBOT', viewValue: 'Chatbot' },
    { value: 'WALLET', viewValue: 'Wallet' },
    { value: 'OTHERS', viewValue: 'Others' }
];


export const MODAL_OPTION = {
    title: '',
    message: '',
    yes: 'YES',
    no: 'NO',
    isHideCancel: false
  }

 export const REVIEW_HEADERS = [
    {label:'All',value:'ALL'},
    {label:'Not Replied',value:'HAVENT'},
    {label:'Replied',value:'REPLIED'},
  ]


