import { Component, OnInit, ViewChild, NgZone, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { GoogleMapService } from './service/google-map.service';

declare const google: any;
@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {
  lat: number = 28.5355161;
  lng: number = 77.39102649999995;
  currentMarker:any;
  mapObj = {
    addressMaps: "",
    lat: null,
    lng: null,
    coordinates: [],/*--long,lat----*/
  }

  searchControl: FormControl;
  address_components:any;
  isMarkerChange:boolean = false;
  @ViewChild('search', {static: true}) searchElementRef: ElementRef
  @Output() locationData  = new EventEmitter();
  @Input('searchBar') searchBar:boolean = true;
  @Input('location')
  set location(location : any ){
    if(location && Object.keys(location).length > 0){
    // console.log(location,'abcd');
      this.isMarkerChange = true;
      this.currentMarker = location;
      this.setCurrentPosition();
    }else{
      this.lat = 28.5355161;
      this.lng= 77.39102649999995;
      if(this.searchControl){
        this.searchControl.setValue(null)
      }
    }

  }

  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _mapService: GoogleMapService
  ) {  
  }
  

  async ngOnInit() {
    this.loadPlaces();
    this.searchControl = new FormControl()
    await this.waitGoogleMapLoad();/*--google map wait--*/
  }

  // set position of map and marker
  private setCurrentPosition() {
    if (this.currentMarker && this.currentMarker.coordinates && this.currentMarker.coordinates.length > 0) {
      this.getAndSetLocationOfGoogleMap(this.currentMarker)
    } else {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        });
      }
    }
  }

  // load maps
  async waitGoogleMapLoad() {
    return this.mapsAPILoader.load().then(() => {
      return true
    }, (error) => {
      return false
    })
  }

  //load Places Autocomplete
  loadPlaces() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {});
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place = google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          /*-------set address on object----------Make address view google map-----*/
          this.getAndSetLocationOfGoogleMap(this._mapService.addressFormatView(place));          
        });
      });
    });
  }

  sendLocationData(){
    this.locationData.emit(this.mapObj);
  }

  // set location of marker
  async getAndSetLocationOfGoogleMap(address: any, inputFocus?: boolean) {
    // console.log(address,"-0-0");
    address = await this._mapService.addressEncode(address.coordinates[1],address.coordinates[0])
    if (address) {
      for (const key in address) {
        if (this.mapObj.hasOwnProperty(key)) {
          this.mapObj[key] = address[key]
        }
      }
      this.lat = Number(address.lat);
      this.lng = Number(address.lng);
      if (inputFocus) {
        this.searchElementRef.nativeElement.focus();
      }
      this.searchControl.setValue(this.mapObj.addressMaps);
      this.sendLocationData();
    }
  }

  markerDragEnd(event){
    this._mapService.addressEncode(event.coords.lat, event.coords.lng).then(address => {
      this.getAndSetLocationOfGoogleMap(address);
    })
  }

}
