import { Injectable } from "@angular/core";
import { Resolve } from '@angular/router';
import { DataTransferService } from './data-transfer.service';

@Injectable({
    providedIn: 'root'
})

export class RouteResolver implements Resolve<any>{
    constructor(private _dataService: DataTransferService){}
    resolve(){
        return this._dataService.getAppConstants().then(data => {
            return this._dataService.getProfileDetail(false)
        })
    }
}