
export const ACCOUNT = 'account';
export const MERCHANT = 'merchant';
export const USER = 'user';
export const LINK_EXPIRED = 'link-expired';
export const LOGIN = `login`;
export const LOGOUT = `logout`;
export const REGISTER = `register`;
export const FORGOT_PASSWORD = `forgot-password`;
export const RESET_PASSWORD = `reset-password`;
export const CHANGE_PASSWORD = `change-password`;
export const EDIT_PROFILE = `edit-profile`;
export const SETTINGS = `settings`;
export const DASHBOARD = `dashboard`;
export const BUSINESS = `business`;
export const USERS = `users`;
export const REVIEWS = `reviews`;
export const UPGRADE = `upgrade`;
export const MY_ACCOUNT = `my-account`;
export const CHAT_BOT = `chat-bot`;
export const CHAT_BOT_PLANS = 'chat-bot-plans';
export const SELF_CHAT_BOT = 'self-chat-bot';
export const DETAILS = `details`;
export const EDIT = `edit`;
export const LIST = `list`;
export const ADD = `add`;
export const EXPLORE = `explore`;
export const CLAIM = `claim`;
export const PROFILE = `profile`;
export const DEV_CENTER =`dev-center`;
export const SUPPORT =`support`;
export const USER_MANAGEMENT =`user-management`;
export const SEARCH_BUISNESS = 'search-buisness';
export const PAYMENT = 'payment';
export const LIGHT_WALLET = 'light-merchant-wallet';
export const BUSINESS_WALLET = 'business-wallet';
export const NOTIFICATION = 'notifications';
export const IMAGE = 'image';
export const STORY = 'story';
export const COUNT = 'count';
export const REPLY = 'reply';
export const INSIGHTS = 'insights';
export const WALLET = 'wallet';

export const ABS_LOGIN =  `/${ACCOUNT}/${LOGIN}`;
export const ABS_FORGOT_PASSWORD = `/${ACCOUNT}/${FORGOT_PASSWORD}`;
export const ABS_RESET_PASSWORD =  `/${ACCOUNT}/${RESET_PASSWORD}`;
export const ABS_REGISTER =  `/${ACCOUNT}/${REGISTER}`;
export const ABS_SETTINGS =  `/${MERCHANT}/${SETTINGS}`;
export const ABS_CHANGE_PASSWORD =  `/${MERCHANT}/${MY_ACCOUNT}/${USER}/${CHANGE_PASSWORD}`;
export const ABS_EDIT_PROFILE =  `/${MERCHANT}/${MY_ACCOUNT}/${USER}/${EDIT}`;
export const ABS_DASHBOARD =  `/${MERCHANT}/${DASHBOARD}`;
export const ABS_USER =  `/${MERCHANT}/${MY_ACCOUNT}/${USER}`;
export const ABS_UPGRADE_ACCOUNT =  `/${MERCHANT}/${UPGRADE}`;
export const ABS_BUSINESS =  `/${MERCHANT}/${BUSINESS}`;
export const ABS_ACCOUNT =  `/${ACCOUNT}/${MY_ACCOUNT}`;
export const ABS_BUSINESS_ADD =  `/${MERCHANT}/${BUSINESS}/${ADD}`;
// export const ABS_BUSINESS_CLAIM =  `/${MERCHANT}/${BUSINESS}/${ADD}`;
export const ABS_BUSINESS_SEARCH =  `/${MERCHANT}/${BUSINESS}/${SEARCH_BUISNESS}`;
export const ABS_BUSINESS_EDIT =  `/${MERCHANT}/${BUSINESS}//${EDIT}`;
export const ABS_BUSINESS_CLAIM =  `/${MERCHANT}/${BUSINESS}/${CLAIM}`;
export const ABS_SEARCH_BUSINESS =  `/${MERCHANT}/${BUSINESS}/${SEARCH_BUISNESS}`;
export const ABS_BUSINESS_DETAILS =  `/${MERCHANT}/${BUSINESS}/${DETAILS}`;
export const ABS_REVIEWS =  `/${MERCHANT}/${REVIEWS}`;
export const ABS_CHAT_BOT =  `/${MERCHANT}/${CHAT_BOT}`;
export const ABS_DEV_CENTER =  `/${MERCHANT}/${DEV_CENTER}`;
export const ABS_SUPPORT =  `/${MERCHANT}/${SUPPORT}`;
export const ABS_USER_MANAGEMENT =  `/${MERCHANT}/${USER_MANAGEMENT}`;
export const ABS_USER_MANAGEMENT_ADD =  `/${MERCHANT}/${USER_MANAGEMENT}/${ADD}`;
export const ABS_USER_MANAGEMENT_EDIT =  `/${MERCHANT}/${USER_MANAGEMENT}/${EDIT}`;
export const ABS_BOT_PLANS = `/${MERCHANT}/${CHAT_BOT}/${CHAT_BOT_PLANS}`;
export const ABS_SELF_BOT = `/${MERCHANT}/${CHAT_BOT}/${SELF_CHAT_BOT}`;
export const ABS_PAYMENT = `/${MERCHANT}/${PAYMENT}`;
export const ABS_WALLET = `/${MERCHANT}/${WALLET}`;
export const ABS_PAYMENT_LIGHT = `/${MERCHANT}/${WALLET}/${LIGHT_WALLET}`;
export const ABS_PAYMENT_BUSINESS = `/${MERCHANT}/${WALLET}/${BUSINESS_WALLET}`;
export const ABS_NOTIFICATION_LIST = `/${MERCHANT}/${NOTIFICATION}`;
export const ABS_IMAGE_LIST = `/${MERCHANT}/${IMAGE}`;
export const ABS_STORY = `/${MERCHANT}/${STORY}`;
export const ABS_INSIGHTS = `/${MERCHANT}/${INSIGHTS}`;
