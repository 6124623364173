// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  // url: 'http://192.82.95.11:2622/v1/merchant/',  
  // botUrl:'http://202.70.46.213:2630/privatejump/',
  // uploadUrl: `http://202.70.46.213/upload/v1/merchant/`,

  // url:'http://localhost:2622/v1/merchant/',
  // url: 'http://10.10.9.48:2622/v1/merchant/',
  url: 'https://ucmerchantdev.appskeeper.com/v1/merchant/',
  botUrl:'https://uchatprivatejump.appskeeper.com/v1/bot/',
  uploadUrl:`https://uchatupload.appskeeper.com/v1/merchant/`,
  

  botLoginUrl:'https://chatbot.tokiapp.mn/portal/login/toki',
  config: {
    BASIC_TOKEN : 'dG9raWFwcDp0b2tpYXBw',
    API_KEY:'1234',
    RE_CAPTCHA_KEY:'6LdkZ6QUAAAAAPBmfE5yBKmYsEKGTanHQRdYrQqZ',
    // RE_CAPTCHA_KEY:'6LeHpb4UAAAAAPxzS5v3JeTPI5iLbjjE0OB9MB3Y',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
