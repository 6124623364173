import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Route,
  CanLoad, Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { ADMIN_URL } from '../constant/urls';
import { POPUP_MESSAGES } from '../constant/messages';
import { UtilityService } from '../modules/shared/services/utility.service';
import { HttpService } from '../modules/shared/services/http.service';
import { ABS_DASHBOARD } from '../constant/routes';

@Injectable()
export class AccountGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _utilityService: UtilityService,
    private _http: HttpService
  ) {

  }


  navigate() {
    this._router.navigate([ABS_DASHBOARD]);
    // return false
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!this._utilityService.getAuthToken()) {

      let token = next.params.token;
      if (token) {
        return this.validateResetPasswordToken(token);
      }
      else {
        return true;
      }
    }
    this.navigate();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (!this._utilityService.getAuthToken()) {
      return true;
    }
    this.navigate();
  }

  validateResetPasswordToken(token) {
    return new Observable<boolean>((observer) => {
      let url = ADMIN_URL.validateToken;
      // this._http.get(url, { token: token }).subscribe(
      //   response => {
      //     if (response['statusCode'] === 200) {
      //       observer.next(true);
      //     } else {
      //       this._utilityService.showAlert(POPUP_MESSAGES.invalidResetPasswordLink);
      //       this._router.navigate([`/${LINK_EXPIRED}`]);
      //       observer.next(false);
      //     }
      //   }, err => {
      //     this._utilityService.showAlert(POPUP_MESSAGES.invalidResetPasswordLink);
      //     this._router.navigate([`/${LINK_EXPIRED}`]);
      //     observer.next(false);
      //   }
      // )
    });
  }
}
