import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules//not-found/not-found.component';
import { AccountGuard } from './guards/account.guard';
import { HomeGuard } from './guards/home.guard';
import { MERCHANT, ACCOUNT, LINK_EXPIRED } from './constant/routes';
import { RouteResolver } from './modules/shared/services/route-resolver.service';

const appRoutes: Routes = [
    { path: '', redirectTo: MERCHANT, pathMatch: 'full' },
    { path: ACCOUNT, loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule), canLoad: [AccountGuard] },
    { path: MERCHANT, loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule), canLoad: [HomeGuard], canActivate: [HomeGuard],resolve: { items: RouteResolver } },
    { path: LINK_EXPIRED, component: NotFoundComponent },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {scrollPositionRestoration:'top'}
        )
    ],
    exports: [
        RouterModule
    ],
    providers: [
    ]

})

export class AppRoutingModule {

}
