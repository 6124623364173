import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { CONSTANTS, COUNTRY } from '../../../constant/urls';
import { MERCHANT } from 'src/app/constant/urls';
import { CLAIM_STATUS, ROLES, ACCESS_TYPE } from 'src/app/constant/options';

@Injectable({
    providedIn: 'root'
})
export class DataTransferService {
    profileDetail = new BehaviorSubject<any>(null);
    isLoading: boolean = false;
    profileData: any;
    constantsData: any;
    countryList: Array<any> = [];
    businessId: string;
    claimStat: string;
    access_type =  ACCESS_TYPE;
    roles = ROLES;
    secretKey:string ;
    stat: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private _http: HttpService
    ) {}

    setStatus(data) {
        this.stat.next(data);
        if(this.profileData){
            this.profileData.businessStatus = data;
        }
    }
    get statusData(): Observable<any> {
        return this.stat.asObservable();
    }

    async getProfileDetail(val = true) {
        if (this.profileData && val) {
            return this.profileData;
        } else {
            this.isLoading = true;
            const response = await this._http.get(MERCHANT);
            if (response) {
                this.isLoading = false;
                if (response['statusCode'] === 200) {
                    console.log(response, "-----------getProfileDetail------------");
                    this.profileData = response.data;
                    this.businessId = response.data.businessId;
                    this.secretKey = response.data.secretKey;
                    this.setStatus(response.data.businessStatus)
                    this.profileDetail.next({...this.profileData});
                    // this.profileData.isBot = '1'
                    return this.profileData;
                } else {
                    return false;
                }
            }
        }
    }

    setSecretKey(key:string){
        this.secretKey = key;
    }

    getSecretKey(){
        return this.secretKey;
    }

    async getClaimStatus(value) {
        let temp;
        this.stat.subscribe(status => {
            if(status){
                if (status == CLAIM_STATUS.PENDING) {
                    temp = false
                } else if (status == CLAIM_STATUS.APPROVED && value == '1') {
                    temp = true
                } else if (status == CLAIM_STATUS.REJECTED && value == '2') {
                    temp = true
                } else {
                    temp = false
                }
            }
        })
        return temp;
    }

    clearUserDetails() {
        this.businessId = '';
        this.profileData = '';
    }

    async getBusinessId() {
        if (this.businessId) {
            return this.businessId;
        } else {
            const resp = await this.getProfileDetail();
            if (resp) {
                return this.businessId;
            }
        }
    }

    async getAppConstants() {
        if (this.constantsData) {
            return this.constantsData;
        } else {
            this.isLoading = true;
            const response = await this._http.get(CONSTANTS);
            if (response) {
                this.isLoading = false;
                if (response['statusCode'] === 200) {
                    this.constantsData = response.data;
                    return response;
                } else {
                    return false;
                }
            }
        }
    }

    setProfileData(data) {
        this.profileData = data;
    }

    getCountryList() {
        if (this.countryList) {
            return this._http.getLocal(COUNTRY).then(data => {
                this.countryList = data;
                return data;
            })
        } else {
            return this.countryList;
        }
    }

    async getAdminRole(value,access) {
        let roleValue = false;
        if(!this.profileData)await this.getProfileDetail();        
        if(this.profileData){
            if(this.profileData.entityType != 'MERCHANT'){    
                let cond1 = this.profileData.role[this.roles[value].value] >= this.access_type[access].value;
                if(cond1)roleValue = true;
                else roleValue = false;
            }else{ return true; }   
            // console.log(roleValue,'roleValue',value);
            return roleValue;
        }        
    }

    getAdminRolePromise(value,access): Promise<boolean>{        
        return new Promise((resolve) => {
            this.getProfileDetail().then(res => {                
                if(res){
                    resolve(this.getAdminRole(value,access));
                }else{
                    resolve(false);
                }
            })          
        })
    }

}
