import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../modules/shared/services/utility.service';
import { ABS_LOGIN, ABS_DASHBOARD } from '../constant/routes';
import { DataTransferService } from '../modules/shared/services/data-transfer.service';

@Injectable()
export class HomeGuard implements CanActivate, CanLoad {
  constructor(
    private _router: Router,
    private _utilityService: UtilityService,
    private _dataTransfer: DataTransferService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    let keys = Object.keys(next.data);
    if (this._utilityService.getAuthToken()) {
      if (keys.length == 0) {
        return true;
      } else {
        return new Promise((resolve) => {
          this._dataTransfer.getAdminRolePromise(next.data.name,next.data.value).then(res => {
            if(res){ resolve(true);}
            else{
              this._utilityService.showAlert('ACCESS_DENIED')
              this._router.navigate([ABS_DASHBOARD]);
              resolve(false);
            }                             
          })
        })

      }
    }
    // return this.navigate();

  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this._utilityService.getAuthToken()) {
      return true;
    }
    return this.navigate();
  }

  navigate() {
    this._utilityService.clearStorage();
    this._router.navigate([ABS_LOGIN]);
    return false;
  }
}
