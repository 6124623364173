import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { POPUP_MESSAGES, SOMETHING_WENT_WRONG } from '../../../constant/messages';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { ImageCropperComponent } from 'src/app/components/image-cropper/image-cropper.component';
import { GoogleMapComponent } from 'src/app/components/google-map/google-map.component';
import { DataTransferService } from './data-transfer.service';
import { TranslateService } from './translate.service';
import { ROLES, ACCESS_TYPE } from 'src/app/constant/options';

@Injectable()
export class UtilityService {
    translateData:any;
    constructor(
        private _dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private _dataTransfer:DataTransferService,
        private _translate:TranslateService
    ) {
    }
    clearStorage() {
        let lang = this.getLang();
        localStorage.clear();
        localStorage.setItem('lang',lang);
    }
    
    getAuthToken() {
        return localStorage.getItem('uchat-merchant-token');    
    }

    getLang() {
        return localStorage.getItem('lang');    
    }

    // to show custom message from front end
    showAlert(message, type?) {
        if(!this.translateData){
            this.translateData = this._translate.data;
        }
        let text = this.translateData[message];
        this._snackBar.open(text, 'Close', {
            duration: 3000,
        });
    }
    // to show message from api response
    showResponseAlert(message, type?) {
        this._snackBar.open(message, 'Close', {
            duration: 3000,
        });
    }
    trim(data) {
        for (const item in data) {
            if (typeof data[item] === 'string') {
                data[item] = data[item].trim();
            }
        }
        return data;
    }
    errorAlert(error) {
        let data = {
            title: '',
            message: (error && error.error && error.error.message) ? (error.error.message) : "SOMETHING_WENT_WRONG",
            yes: "CLOSE",
            isHideCancel: true
        }
        this.openDialog(data).subscribe(success => {

        });
    }
    openDialog(data) {
        let newData = this.getLanguageBasedObj(data);
        const dialogRef = this._dialog.open(ConfirmationModalComponent, {
            width: '500px',
            data: newData
        });
        return dialogRef.afterClosed();
    }

    // to show text based on language
    getLanguageBasedObj(obj){
        if(!this.translateData){
            this.translateData = this._translate.data;
        }
        let newObj = {};
        if(this._translate.data[obj.message] === null || this._translate.data[obj.message] === undefined){
            newObj['message'] = obj.message;
        }else{
            newObj['message'] = this._translate.data[obj.message];
        }   
        if(this._translate.data[obj.title] === null || this._translate.data[obj.title] === undefined){
            newObj['title'] = obj.title;
        }else{
            newObj['title'] = this._translate.data[obj.title];
        }        
        if(!obj.isHideCancel){
            newObj['no'] = 'No';
        }else{
            newObj['isHideCancel'] = true;
        }
        newObj['yes'] = 'Yes';        
        return newObj;
    }

    openCropper() {
        const dialogRef = this._dialog.open(ImageCropperComponent, {
            width: '700px',
            data: ''
        });
        return dialogRef.afterClosed();
    }

    openMaps(data) {        
        const dialogRef = this._dialog.open(GoogleMapComponent, {
            width: '700px',
            data: data
        });
        return dialogRef.afterClosed();
    }


    async download(file) {
        const constants = await this._dataTransfer.getAppConstants();
        var element = document.createElement('a');
        element.setAttribute('href', `${constants.csvBaseUrl}${file}`);
        element.setAttribute('download', file);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }


    getImagewithPath(imageName) {
        return this._dataTransfer.getAppConstants().then(data => {
            console.log(data,"dd");
            
            return `${data.imageBaseUrl}${imageName}`;
        })
    }

    getDocumentwithPath(imageName) {
        return this._dataTransfer.getAppConstants().then(data => {
            return `${data.imageBaseUrl}${imageName}`;
        })
    }

    setEndDateHours(date) {
        return new Date(new Date(date).setHours(23, 59, 59, 999));
    }

    getFileFromBlob(file){
        let arr = [];
        file.forEach(element => {
            arr.push(new File([element.file],element.name))
        });
        return arr;
    }

    adminAccessRole(obj){
        let arr = [];
        Object.values(ROLES).forEach(el =>{
            if(obj[el.value] !== undefined){
                Object.values(ACCESS_TYPE).forEach(element => {                    
                    if(element.value == obj[el.value]){                
                        arr.push({
                            'name':el.name,
                            'access':element.name
                        })
                    }                    
                })
            }            
        })
        return arr;        
    }

}

