import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpService } from './services/http.service';
import { UtilityService } from './services/utility.service';
import { TokenInterceptor } from '../../Interceptors/token.interceptor';
import { HomeGuard } from '../../guards/home.guard';
import { AccountGuard } from '../../guards/account.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AbsoluteRoutingModule } from '../../pipes/absolute-routing/absolute-routing.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
@NgModule({
  imports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatToolbarModule,
    // ImageCropModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    // HttpClientModule,
    FormsModule,
    AbsoluteRoutingModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule
  ],
  declarations: [
    ConfirmationModalComponent
  ],
  exports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatCardModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatSnackBarModule,
    RouterModule,
    HttpClientModule,
    MatToolbarModule,
    // ImageCropModule,
    // HttpClientModule,
    FormsModule,
    AbsoluteRoutingModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    // MatNativeDateModule
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule
  ],
  entryComponents: [
    ConfirmationModalComponent,
  ],
  providers: [
    HttpService,
    UtilityService,
    HomeGuard,
    AccountGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
