import { Pipe, PipeTransform } from '@angular/core';
import * as routes from '../../constant/routes';

@Pipe({
  name: 'absolutePath'
})
export class AbsoluteRoutingPipe implements PipeTransform {

  // async transform(value: string) {
  //   const route = await import(`../../constant/routes/${value}`);
  //   return Promise.resolve(route);
  // }
  transform(route) {
    // console.log(routes[route])
    return routes[route];
  }
}
