import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { UtilityService } from '../modules/shared/services/utility.service';
import { LoaderService } from '../modules/shared/services/loader.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  isBotApi: boolean = false;
  botUrl: string = environment.botLoginUrl;
  constructor(
    private router: Router,
    private _utilityService: UtilityService,
    private _loaderService: LoaderService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url == this.botUrl) {
      this.isBotApi = true;
    } else {
      this.isBotApi = false;
    }

    let headers = {};
    const basicToken = environment.config.BASIC_TOKEN;
    const token = this._utilityService.getAuthToken();
    if (!this.isBotApi) {
      if (token) {
        headers['authorization'] = 'Bearer ' + token;
      } else {
        headers['authorization'] = 'Basic ' + basicToken;
      }
    }
    // headers['api-Key'] = environment.config.API_KEY;
    const lang = this._utilityService.getLang();
    if (lang) {
      headers['Accept-Language'] = lang;
    }

    request = request.clone({
      setHeaders: headers
    });

    // console.log(request, '00000000000000')
    return next.handle(request).pipe(
      tap(
        (data) => {
          if (data instanceof HttpResponse) {
            this._loaderService.loader.next(false);
          }
        },
        (err: any) => {
          this._loaderService.loader.next(false);
          if (err instanceof HttpErrorResponse) {
            this._utilityService.errorAlert(err);
            if (err.status === 401 || err.error.responseType == 'UNAUTHORIZED') {
              this._utilityService.clearStorage();
              this.router.navigate(['account/login']);
            }
          }
        }
      ));
  }
}