export const VALIDATION_CRITERIA = {
    emailMaxLength : 50,
    passwordMinLength : 8,
    passwordMaxLength : 20,
    nameMinLength : 2, 
    nameMaxLength : 100,
    subTitleMinLength : 2, 
    subTitleMaxLength : 150,
    titleMinLength : 2, 
    titleMaxLength : 60,
    phoneMinLength : 5,
    phoneMaxLength : 10,
    locationMinLength:2,
    locationMaxLength:1000,
    descriptionMinLength:1,
    descriptionMaxLength:250,
    priceMinLength:1,
    priceMaxLength:10,
    fileSize:1024 * 1000 * 10,
    shortDescMinLength : 5, 
    shortDescMaxLength : 30,
    categoryMinLength:4,
    categoryMaxLength:100,
    countryMinLength:3,
    countryMaxLength:10,
    postalMinLength:3,
    postalMaxLength:7,
    mPinMinLength:3,
    mPinMaxLength:10,
    mPinLength:4,
    nationalIdLength:8
};

export const IMAGETYPE = ["image/jpg","image/jpeg","image/png"];

export const VIDEOTYPE = ["video/mp4"];

export const FILETYPE = ["jpg","jpeg","png","pdf","docx","doc"];

export const BUSINESSS_PROOF = ["jpg","jpeg","png","pdf"];  