import { environment } from 'src/environments/environment';

export const ADMIN_URL = {
    login: "admins/login",
    register: "admins/register",
    forgot: "admins/forgot-password",
    changePassword: "admins/change-password",
    admin: 'admins/',
    reset: "admins/reset-password",
    logout: "admins/logout",
    validateToken: "admins/verify-token",
    miscellaneous: 'admins/miscellaneous',
    reports: 'admins/reports',
    reportDetails: 'admins/report-details',
    // categoryList:'',
    subCategoryList: '',
    businessList: ''
}

const LOCAL_PATH: string = "/assets/json/";


export const LOGIN = 'login';

export const LOGOUT = "logout";

export const CHANGE_PASSWORD = "change-password";

export const FORGOT_PASSWORD = "forget-password";

export const CONSTANTS = "constants";

export const CLAIM_BUSINESS = "claim-business";

export const CLAIM_BUSINESS_UNAUTH = "unauth-claim-business";

export const MERCHANT = "details";

export const REGISTER = "register";

export const LABELS = "labels";

export const TAGS = "tags";

export const CONFIGURATION = "configuration";

export const DASHBOARD = "dashboard";

export const BUSINESS_LOCATION = "near-by-business";

export const BUSINESS_BY_SUBCATEGORY = "business-by-subcategory";

export const BUSINESS = "business";

export const BUSINESS_UNAUTH = "unauth-business";

export const NOTIFICATION = "notification";

export const CATEGORY = "category";

export const CATEGORY_UNAUTH = "unauth-category";

// export const CATEGORY_LIST = "category-list";

export const COUNTRY = "assets/json/country.json";

export const UPLOAD_IMAGE_FILE = "upload-image";

export const UPLOAD_DOC_FILE = "upload-document";

export const UPLOAD_DOC_FILE_UNAUTH = "unauth-upload-document";

export const USERS = "sub-merchant";

export const SEND_AUTH_OTP = "auth-send-otp";

export const SEND_UNAUTH_OTP = "unauth-send-otp";

export const VERIFY_AUTH_OTP = "auth-verify-otp";

export const VERIFY_UNAUTH_OTP = "unauth-verify-otp";

export const I18N = `${LOCAL_PATH}i18n/`;

export const BOT_PROVISION = "provisioning";

export const BOT_TOKEN = "bot-get-started";

export const REQUEST_ADMIN = "request-admin";

export const API_LIST = "api-collection";

export const FAQ = "faq";

export const QUERY = "merchant-query";

export const RATING = "rating";

export const IMAGE_HEADERS = "image-headers";

export const IMAGES = "business-images";

export const STORY = "story";

export const BANKS = "banks";

export const SEARCH_BUSINESS = 'business-by-name-location';

export const UPLOAD = {
    baseUrl: `${environment.uploadUrl}`,
    uploadDocument: 'upload-document',
    uploadImage: 'upload-image'
};

export const COMPLETE_KYC = 'kyc';

export const SEND_MPIN = "kyc-mpin";

export const GET_WALLET_BALANCE = 'obopay/getwalletbalance';

export const TRANSACTION_HISTORY = 'obopay/get-transaction-history';