import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LoaderService } from './loader.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private apiUrl: string;
    private botUrl: string;

    constructor(
        private http: HttpClient,
        private _loaderService: LoaderService
    ) {
        this.apiUrl = environment.url;
        this.botUrl = environment.botUrl;
    }


    async post(url, data, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return await this.promisify(this.http.post(this.apiUrl + url, data));

    }


    async postWithToken(url, data, token, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        let headers = {
            headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
        }

        return await this.promisify(this.http.post(this.apiUrl + url, data, headers));
    }

    async put(url, data, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return await this.promisify(this.http.put(this.apiUrl + url, data));
    }

    async patch(url, data?, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        const resp = await this.promisify(this.http.patch(this.apiUrl + url, data));
        return resp;
    }

    async delete(url, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return await this.promisify(this.http.delete(this.apiUrl + url))

    }

    async get(url, httpParams?: any, loader = true): Promise<any> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        const header = {};
        if (httpParams) {
            header['params'] = httpParams;
        }
        return await this.promisify(this.http.get(this.apiUrl + url, header));
    }

    getWithObservable(url, httpParams?: any, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        const header = {};
        if (httpParams) {
            header['params'] = httpParams;
        }
        return this.http.get(this.apiUrl + url, header);
    }

    getLocal(url) {
        return this.promisify(this.http.get(url));
    }


    // server url redirect to bot/token
    async getBot(url, httpParams?: any, loader = true): Promise<any> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        const header = {};
        if (httpParams) {
            header['params'] = httpParams;
        }
        return await this.promisify(this.http.get(this.botUrl + url, header));
    }


    async getChatBot(url, data: any, loader = true): Promise<any> {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return this.http.get(url, data).toPromise();

    }


    async postChatBot(url, data, loader = true) {
        if (loader) {
            this._loaderService.loader.next(loader);
        }
        return await this.promisify(this.http.post(this.botUrl + url, data));

    }

    promisify(observable: Observable<any>): Promise<any> {
        return new Promise((resolve, reject) => {
            observable.subscribe(resolve, reject);
        });
    }


}
