import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { VALIDATION_CRITERIA, IMAGETYPE } from 'src/app/constant/validation-criteria';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ImageCropperComponent>
  ) { }

  @Output() imageData = new EventEmitter<any>();
  @Output() closeCropper = new EventEmitter<any>();
  @ViewChild('cropper', {static: true}) myCopper: ImageCropperComponent;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageDataObj: any;
  fileName:string;
  isFileSelected: boolean = false;
  isCropperOpen: boolean = true;
  fileSizeInvalid: boolean = false;
  msg ="";
  imageSize = VALIDATION_CRITERIA.fileSize;
  fileTypeArr = IMAGETYPE;
  ngOnInit() {
  }

  // on file upload
  fileChangeEvent(event: any): void {
    
    if(event){
      let file: File = event.target.files[0];
      this.fileName = file.name;
      let size;
      let type;
      if(file){
        size = Math.round(file.size / 1024);
        type = file.type;
      }
      // validations
      if(!this.checkFileType(type)){
        this.fileSizeInvalid = true;
        this.msg = 'Please select an image format file.';
      }else if(this.checkFileSize(size)){

        this.fileSizeInvalid = true;
        this.msg = 'Image size must be less then 10 MB.'
      }else{
        this.imageChangedEvent = event;
        this.isFileSelected = true;
      }
    }
  }

  // event on cropping image
  imageCropped(event) {    
    this.imageDataObj = event;
    this.croppedImage = event.base64;
  }

  // to send image data and close cropper
  crop() {    
    this.imageDataObj['name'] = this.fileName;    
    this.dialogRef.close(this.imageDataObj)
  }

  // go back to uplad image 
  backToUploadFile() {
    this.isFileSelected = false;
  }

  // cancel cropper
  cancelCropper() {
    this.isCropperOpen = false;
    this.dialogRef.close('')
  }

  // check file type
  checkFileType(type){
    return this.fileTypeArr.includes(type);
  }

  checkFileSize(size){
    return size > this.imageSize;;
  }

}
