import { Injectable } from '@angular/core';
declare const google: any;
@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  constructor() { }

  addressFormatView(place: any) {
    let formatAddress = {
      addressMaps: "",
      // city: "",
      // state: "",
      // postal_code:"",
      lat: null,
      lng: null,
      // country:'',
      // locality:'',
      // subLocality:'',
      coordinates: [],/*--long,lat----*/
    }
    // formatAddress.googlePlace = place;
    formatAddress.coordinates[0] = place.geometry.location.lng();
    formatAddress.coordinates[1] = place.geometry.location.lat();
    formatAddress.lat = place.geometry.location.lat();
    formatAddress.lng = place.geometry.location.lng();
    formatAddress.addressMaps = place.formatted_address;
    let obj = place.address_components
    // obj.find(data => {
    //   if(data.types.includes("postal_code")){
    //     formatAddress.postal_code = data.long_name;
    //   }
    //   if(data.types.includes("country")){
    //     formatAddress.country = data.long_name;
    //   }
    //   if(data.types.includes("administrative_area_level_1")){
    //     formatAddress.state = data.long_name;
    //   }
    //   if(data.types.includes("administrative_area_level_2")){
    //     formatAddress.city = data.long_name;
    //   }
    //   if(data.types.includes("locality")){
    //     formatAddress.locality = data.long_name;
    //   }      
    //   if(data.types.includes("sublocality_level_1")){
    //     formatAddress.subLocality = data.long_name;
    //   }   
    //   if(data.types.includes("sublocality_level_2")){
    //     formatAddress.subLocality = `${data.long_name} ${formatAddress.subLocality}`;
    //   }   
      
    // })
    // formatAddress.url = place.url;
    // if (place.address_components.length > 2) {
    //   formatAddress.city = place.address_components[0].long_name;/*--city--*/
    //   formatAddress.state = place.address_components[place.address_components.length - 2].long_name;/*--state--*/
    // }
    // console.log(formatAddress,'09090909');
    
    return formatAddress
  }

  addressEncode(lat: any, long: any) {
    return new Promise((resolve, reject) => {
      let geocoder = new google.maps.Geocoder;
      var latlng = { lat: +lat, lng: +long };
      geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (status === 'OK') {
          
          // let formatAddress = this.addressFormatView(results[0]);
          let formatAddress = this.addressFormatView(results[0]);
          resolve(formatAddress);
        } else {
          reject(false);
          // this.toast.error('Geocoder failed due to');
        }
      })
    });
  }

  // completeAddressFormat(obj){
  //   console.log(obj);
  //   let formatAddress = {
  //     address: "",
  //     city: "",
  //     state: "",
  //     postal_code:"",
  //     lat: null,
  //     lng: null,
  //     country:'',
  //     locality:'',
  //     subLocality:'',
  //     coordinates: [],/*--long,lat----*/
  //   }

  //   obj.find(data => {
  //     if(data.types.includes("postal_code")){
  //       formatAddress.postal_code == data.long_name;
  //     }
  //     if(data.types.includes("country")){
  //       formatAddress.country = data.long_name;
  //     }
  //     if(data.types.includes("administrative_area_level_1")){
  //       formatAddress.state = data.long_name;
  //     }
  //     if(data.types.includes("administrative_area_level_2")){
  //       formatAddress.city = data.long_name;
  //     }
  //     if(data.types.includes("locality")){
  //       formatAddress.locality = data.long_name;
  //     }      
  //     if(data.types.includes("sublocality_level_1")){
  //       formatAddress.subLocality = data.long_name;
  //     }   
  //     if(data.types.includes("sublocality_level_2")){
  //       formatAddress.subLocality = `${data.long_name} ${formatAddress.subLocality}`;
  //     }   
      
  //   })
  //   console.log(formatAddress,"formatAddress");


  //   return formatAddress;
  // }





}
