import { Injectable } from '@angular/core';
import {  HttpService } from './http.service';
import { I18N } from 'src/app/constant/urls';
import { DataTransferService } from './data-transfer.service';


@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  data: any = {};
  constructor(private _http: HttpService) { }
  use(): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `${I18N}${this.language || 'en'}.json`;
      
      this._http.getLocal(langPath).then(translation => {
        this.data = Object.assign({}, translation || {});
        resolve(this.data);
      },
        error => {
          this.data = {};
          this.deleteLanguage();
          window.location.reload();
          resolve(this.data);
        }
      );
    });
  }

  getText(){
    if(this.data){
      return this.data;
    }else{
      return null;
    }
  }

  /*-------location setting--------*/

  setLanguage(lang: string) {
    localStorage.setItem('lang', lang)
  }

  get language() {
    const getLanguage = localStorage.getItem('lang');
    if (getLanguage) {
      return getLanguage
    } else {
      this.setLanguage('en');
      return 'en'
    }
  }

  deleteLanguage(){
    localStorage.removeItem('lang');
    this.setLanguage('en');
  }

}
